.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex: 1;
  max-height: 100%;
  overflow-x: hidden;
}
