.App {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  flex: 1;
}
